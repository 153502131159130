<template lang="pug">
#release-notes
  h1.main-page-title._margin Список изменений (внешний интерфейс)
  span.description
    | Ниже представлен список изменений внешнего интерфейса. Вам также доступен список изменений
    |
    router-link.link(:to="{ name: Tabs.ReleaseNotesBack }") серверной части.
  logs
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Tabs } from "@/router/tabs";
import Logs from '@/CHANGELOG.md'

export default defineComponent({
  name: "ReleaseNotesFront",
  components: {
    Logs,
  },
  setup() {
    return {
      Tabs,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import "@/assets/styles/mixin/links";

#release-notes {
  @include adaptive-view;

  .description {
    font-size: 14px;
    line-height: 18px;

    .link {
      @include link-mixin;
    }
  }

  :deep(.markdown-body) {
    h2 {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: var(--default-blue-color);
      margin-top: 32px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }

    li {
      font-size: 14px;
      line-height: 18px;
    }

    ol {
      margin: 0;
      padding-inline-start: 72px;
    }
  }
}
</style>
